import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { colors, fonts } from '../theme/variables';
import HeadingPrimary from '../components/heading-primary';

const Files = styled.ul`
  padding: 3rem 5rem;
`;

const File = styled.li`
  & + & {
    margin-top: 2em;
  }
`;

const FileLink = styled.a`
  font-family: ${fonts.sourceSansPro};
  font-size: 2.2rem;
  color: ${colors.accent};

  :hover {
    color: ${colors.accentLighten};
  }

  :active {
    color: ${colors.accentDark};
  }
`;

const Documents = ({ data }) => {
  const { allMarkdownRemark } = data;
  const { nodes } = allMarkdownRemark;
  return (
    <Layout>
      <SEO title="Jogi dokumentumok" />
      <HeadingPrimary>Jogi dokumentumok</HeadingPrimary>
      <Files>
        {nodes.map(node => {
          const { frontmatter, id } = node;
          const { src } = frontmatter;
          const fileName = src.replace('/assets/', '');
          return (
            <File key={id}>
              <FileLink href={src}>{fileName}</FileLink>
            </File>
          );
        })}
      </Files>
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/documents/" } }) {
      nodes {
        fileAbsolutePath
        id
        frontmatter {
          src
        }
      }
    }
  }
`;

export default Documents;
