import styled from 'styled-components';
import { fonts, colors } from '../theme/variables';

const HeadingPrimary = styled.h1`
  font-family: ${fonts.Nunito};
  font-style: normal;
  font-weight: 700;
  font-size: 1.8em;
  line-height: normal;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  text-align: center;
  margin: 1em 0;

  color: ${colors.black};
`;

export default HeadingPrimary;
